.breadcrumbs-bar {
  color: theme('colors.white');
  background-image: url('/images/main-background-image.jpg');
  background-size: 100%;
  background-position: center 15%;
  background-repeat: repeat-x;

  @screen md {
    background-size: 65%;
  }
}
