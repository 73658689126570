@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import './base';
}

@layer components {
  @import './components';
}

@layer utilities {
  @import './utilities';
}

body {
  font-family: theme('fontFamily.grotesk');
  color: theme('colors.gray.500');
  max-width: 1828px;
  margin: auto;
  box-shadow: theme('boxShadow.xl');
  width: 100%;

  #__next {
    overflow: hidden;
  }

  main {
    overflow: hidden;
  }
}

.wysiwyg-table {
  overflow-x: auto;
}

[aria-label='Map'] {
  [role='dialog'] {
    overflow: visible !important;
    border-radius: 2px;
    box-shadow: none;

    .gm-info-window {
      font-family: theme('fontFamily.barlow');
      font-size: theme('fontSize.base');
      margin: 0.25rem;

      .name {
        font-family: theme('fontFamily.grotesk');
        font-weight: theme('fontWeight.semibold');
        margin-bottom: 0.5rem;
      }

      p {
        margin: 0;
      }

      > div {
        font-size: theme('fontSize.sm');

        p {
          margin-bottom: 0.125rem;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    > button {
      background: red !important;
      top: 0;
      right: 0;
      transform: translate(25%, -25%);
      width: 1.5rem !important;
      height: 1.5rem !important;
      display: grid !important;
      place-content: center;
      border-radius: 9999px;
      background: #27272b !important;
      opacity: 1;

      span {
        background: white !important;
      }
    }
  }
}

.carousel {
  .control-dots {
    margin: 0 !important;
    text-align: left !important;
    width: fit-content !important;
    right: 1rem !important;
    top: calc(var(--media-height) * 1px - 5.5rem);

    @screen md {
      top: calc(var(--media-height) * 1px - 2.5rem);
    }

    .dot {
      opacity: 1 !important;
      box-shadow: none !important;
      width: 1rem !important;
      height: 1rem !important;
      border: 1px solid white !important;
      border-radius: 9999px !important;
      background: transparent !important;
      transition: background var(--speed) ease;
      margin: 0 0.25rem !important;

      &.selected {
        background: white !important;
      }
    }
  }
}

.wysiwyg-button {
  padding: 0.625rem 1.5rem;
  display: inline-block;
  border-radius: 9999px;
  color: theme('colors.beige.light');
  background: theme('colors.yellow.base');
  font-family: theme('fontFamily.grotesk');
  font-weight: theme('fontWeight.semibold');
  font-size: theme('fontSize.sm');
  letter-spacing: theme('letterSpacing.widest');
  text-transform: uppercase;
  border: 1px solid transparent;
  transition: all 0.25s ease;

  @screen sm {
    padding: 0.625rem 2rem;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    text-decoration: none;
    box-shadow: 0 8px 40px rgba(234, 177, 24, 0.3);
    background: theme('colors.yellow.highlight');
  }
}
