.badge--member {
  padding: 0.25rem !important;
  font-size: 10px !important;
  background: theme('colors.yellow.base');
  color: theme('colors.white');

  svg {
    width: 0.75rem;
    height: 0.75rem;
    flex-shrink: 0;
    margin-right: 0.25rem;
  }
}
