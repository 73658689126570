@import '@/styles/base/container';

.header-with-search-form {
  @extend .container;
  background: theme('colors.beige.light');
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;

  @screen sm {
    flex-direction: row;
    align-items: center;
    padding-top: 3rem;
  }

  h1 {
    font-family: theme('fontFamily.sangbleu');
    font-size: theme('fontSize.4xl');
    margin-top: 3rem;

    @screen sm {
      margin: unset;
    }
  }

  form {
    margin: 0 calc(var(--container-space-x) * -1);
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;

    @screen sm {
      padding-top: unset;
      padding-bottom: unset;
      margin: unset;
    }
  }
}
